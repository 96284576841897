import './css/Admin.css';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Row, Col, Table, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter , InputGroup } from 'reactstrap'
import { baseUrl, base64Data, base64Data2 } from '../shared/baseUrl';

import { GrMultiple, GrDocumentUser } from 'react-icons/gr';
import { SiMicrosoftexcel } from 'react-icons/si';
import { ImCopy } from 'react-icons/im';
import { FiEdit } from "react-icons/fi";
import { MdOutlineCalculate } from "react-icons/md";
import { read, write, utils } from 'xlsx';
import ToggleSwitch from './components/toggleswitch';
import axios from 'axios';


import { saveAs } from 'file-saver';
import Docxtemplater from 'docxtemplater';
import DocxMerger from 'docx-merger';

import PizZip from 'pizzip';
import { useSearchParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { NavLink } from 'react-router-dom';
import { HiOutlineDocumentSearch } from "react-icons/hi";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import WebViewer from "@pdftron/webviewer";



function parseDateTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const options = {
    day: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };
  const formattedDate = dateTime.toLocaleString('fr-FR', options);
  return formattedDate.replace('à', '•');
}
function parseDateTime2(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const day = dateTime.getDate().toString().padStart(2, '0');
  const month = (dateTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so we add 1
  const year = dateTime.getFullYear();
  return `${day}-${month}-${year}`
}
function getObjectValue(obj, path) {
  const parts = path.split('.');
  let value = obj;

  for (const part of parts) {
    value = value[part];
    if (value === undefined) {
      return '';
    }
  }


  if((parts.includes('ADD') || parts.includes('CYL') || parts.includes('SPH')) ){
    value = parseFloat(value).toFixed(2);
    if(value > 0) {
      value = '+ ' + value;
    }
  }
  return value;
}

function loadFile(file, callback) {
  const reader = new FileReader();
  reader.onload = function (event) {
    const content = event.target.result;
    callback(null, content);
  };
  reader.onerror = function (event) {
    callback(event.target.error, null);
  };
  reader.readAsArrayBuffer(file);
}

function loadFileFromLocal(file, callback) {
  fetch('../assets/OLAB_BON_DE_COMMANDE.docx')
  .then(row => row.arrayBuffer())
  .then(text => {
    console.log(text)
  })
  // const reader = new FileReader();
  // reader.onload = function (event) {
  //   const content = event.target.result;
  //   callback(null, content);
  // };
  // reader.onerror = function (event) {
  //   callback(event.target.error, null);
  // };
  // reader.readAsArrayBuffer(file);
  // fs.readFileSync(path.resolve(__dirname, "../assets/OLAB_BON_DE_COMMANDE.docx"))
}

const loadLocalFile = async() => {
  const file = await fetch('OLAB_Bon_de_COMMANDE.docx');
  const buffer = await file.arrayBuffer();
  return buffer;
}


async function handleFileChange(event, order) {
  const selectedFiles = event.target.files;

  if (!selectedFiles || selectedFiles.length === 0) {
    alert('Please select DOC files.');
    return;
  }
  for (const selectedFile of selectedFiles) {
    await new Promise((resolve, reject) => {
      loadFile(selectedFile, (error, content) => {
        if (error) {
          console.error('Error reading file:', error);
          return;
        }
        const InspectModule = require("docxtemplater/js/inspect-module");
        const iModule = InspectModule();
        var zip = new PizZip(content);
        var doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          modules: [iModule]
        });
        const tags = iModule.getAllTags();

        for (const key in tags) {
          tags[key] = getObjectValue(order, key);
        }

        if (order?.product?.rx?.OD == "Ne pas commander" || order?.product?.rx?.OS == "Ne pas commander") {
          if (Object.keys(tags).includes('product.lens.design.OS')) {
            tags['product.lens.design.OS'] = ''
          }
        }

        if (Object.keys(tags).includes('expedition')) {
          tags['expedition'] = new Date(order?.expedition).toLocaleDateString('en-CA');
        }
        if (Object.keys(tags).includes('created')) {
          tags['created'] = new Date(order?.created).toLocaleDateString('en-CA');
        }
        tags['detailedprice.design'] = parseFloat(tags['detailedprice.design'] ).toFixed(2);
        tags['detailedprice.designbis'] = parseFloat(tags['detailedprice.designbis'] ).toFixed(2);
        tags['detailedprice.frameType'] = parseFloat(tags['detailedprice.frameType'] ).toFixed(2);
        tags['detailedprice.tailler'] = parseFloat(tags['detailedprice.tailler'] ).toFixed(2);
        tags['detailedprice.curve'] = parseFloat(tags['detailedprice.curve'] ).toFixed(2);
        tags['detailedprice.shade'] = parseFloat(tags['detailedprice.shade'] ).toFixed(2);
        tags['detailedprice.prisme'] = parseFloat(tags['detailedprice.prisme'] ).toFixed(2);
        tags['detailedprice.mirror'] = parseFloat(tags['detailedprice.mirror'] ).toFixed(2);
        tags['detailedprice.manutention'] = parseFloat(tags['detailedprice.manutention'] ).toFixed(2);
        tags['detailedprice.extraOption'] = parseFloat(tags['detailedprice.extraOption'] ).toFixed(2);
        doc.setData(tags);

        try {
          doc.render();
        } catch (error) {

          console.error('Error rendering document:', error);
          return;
        }

        var out = doc.getZip().generate({
          type: 'blob',
          mimeType:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });
        // Output the document using Data-URI
        saveAs(out, `${order?.user?.code}-${order._id}-${selectedFile.name.replace(/\.[^/.]+$/, '')}.docx`);
        resolve();
      });

    })
  }
  event.target.value = ""
}
async function transformDoc(id) {
  const docInput = document.getElementById('docInput' + id)
  docInput.click();
}

function treatmentConverter(column1, column2) {

  const mappings = {
    'NU': {
      'Argent': 'Mirror Silver',
      'Or': 'Mirror Gold',
      'Mauve': 'Mirror Purple',
      'Rose': 'Mirror Pink',
      'Champagne': 'Mirror Champagne',
      'Bleu': 'Mirror Blue',
      'Vert': 'Mirror Green',
      'Rouge': 'Mirror Red',
    },
    'HC+1': {
      'Argent': 'Mirror Silver',
      'Or': 'Mirror Gold',
      'Mauve': 'Mirror Purple',
      'Rose': 'Mirror Pink',
      'Champagne': 'Mirror Champagne',
      'Bleu': 'Mirror Blue',
      'Vert': 'Mirror Green',
      'Rouge': 'Mirror Red',
    },
    'HC+2': {
      'Argent': 'Mirror Silver',
      'Or': 'Mirror Gold',
      'Mauve': 'Mirror Purple',
      'Rose': 'Mirror Pink',
      'Champagne': 'Mirror Champagne',
      'Bleu': 'Mirror Blue',
      'Vert': 'Mirror Green',
      'Rouge': 'Mirror Red',
    },
    'HMC PLUS EC': {
      'Argent': 'Mirror Silver W/ BHMC',
      'Or': 'Mirror Gold W/ BHMC',
      'Mauve': 'Mirror Purple W/ BHMC',
      'Rose': 'Mirror Pink W/ BHMC',
      'Champagne': 'Mirror Champagne W/ BHMC',
      'Bleu': 'Mirror Blue W/ BHMC',
      'Vert': 'Mirror Green W/ BHMC',
      'Rouge': 'Mirror Red W/ BHMC',
    },
    'ULTRATRANS EC A/R': {
      'Argent': 'Mirror Silver W/ BHMC',
      'Or': 'Mirror Gold W/ BHMC',
      'Mauve': 'Mirror Purple W/ BHMC',
      'Rose': 'Mirror Pink W/ BHMC',
      'Champagne': 'Mirror Champagne W/ BHMC',
      'Bleu': 'Mirror Blue W/ BHMC',
      'Vert': 'Mirror Green W/ BHMC',
      'Rouge': 'Mirror Red W/ BHMC',
    },
    'ULTRATRANS EC A/R (Résiduel Bleu)': {
      'Argent': 'Mirror Silver/Blue Block AR',
      'Or': 'Mirror Gold/Blue Block AR',
      'Mauve': 'Mirror Purple/Blue Block AR',
      'Rose': 'Mirror Pink/Blue Block AR',
      'Champagne': 'Mirror Champagne/Blue Block AR',
      'Bleu': 'Mirror Blue/Blue Block AR',
      'Vert': 'Mirror Green/Blue Block AR',
      'Rouge': 'Mirror Red/Blue Block AR',
    },
    'ULTRATRANS A/R BLUE UV': {
      'Argent': 'Mirror Silver/Blue Block AR',
      'Or': 'Mirror Gold/Blue Block AR',
      'Mauve': 'Mirror Purple/Blue Block AR',
      'Rose': 'Mirror Pink/Blue Block AR',
      'Champagne': 'Mirror Champagne/Blue Block AR',
      'Bleu': 'Mirror Blue/Blue Block AR',
      'Vert': 'Mirror Green/Blue Block AR',
      'Rouge': 'Mirror Red/Blue Block AR',
    },
    'ULTRATRANS A/R Sun UV': {
      'Argent': 'Mirror Silver W/ BHMC',
      'Or': 'Mirror Gold W/ BHMC',
      'Mauve': 'Mirror Purple W/ BHMC',
      'Rose': 'Mirror Pink W/ BHMC',
      'Champagne': 'Mirror Champagne W/ BHMC',
      'Bleu': 'Mirror Blue W/ BHMC',
      'Vert': 'Mirror Green W/ BHMC',
      'Rouge': 'Mirror Red W/ BHMC',
    },
    'ULTRATRANS A/R ANTI-FOG': {
      'Argent': 'Mirror Silver W/ Anti-Fog BHMC',
      'Or': 'Mirror Gold W/ Anti-Fog BHMC',
      'Mauve': 'Mirror Purple W/ Anti-Fog BHMC',
      'Rose': 'Mirror Pink W/ Anti-Fog BHMC',
      'Champagne': 'Mirror Champagne W/ Anti-Fog BHMC',
      'Bleu': 'Mirror Blue W/ Anti-Fog BHMC',
      'Vert': 'Mirror Green W/ Anti-Fog BHMC',
      'Rouge': 'Mirror Red W/ Anti-Fog BHMC',
    },
  };

  const key1 = column2
  const key2 = column1
  if (mappings[key1] && mappings[key1][key2]) {
    return mappings[key1][key2];
  } else {
    return null;
  }
}
function frameTypeConverter(thickness, type, index) {
  if (thickness == 'Normal') {
    switch (type) {
      case 'Plastique':
        return 'Plastic';
      case 'Métal':
        return 'Metal';
      case 'Nylon':
        return 'Rimless';
      case 'Fil métal':
        return 'Rimless';
      case 'Percée':
        return 'Rimless';
      default:
        return 'Rimless'
    }
  }
  else if (thickness == 'Industrielle') {
    if (index.includes('1.59')) {
      return 'Safety 2mm'
    }
    else {
      return 'Safety 3mm'
    }
  }
  return 'KO'
}
function converterExport(value, addValue = false, treatment = false) {
  const cases = {
    "B.int": 'IN',
    "B.ext": 'OUT',
    "B.haut": 'UP',
    "B.bas": 'DOWN',
    'Access HD 1.0': 'MD9',
    'Access HD 1.0 Court': 'MD7',
    'Access HD 1.0 Long': 'MD11',
    'Access HD 2.0': '2MD9',
    'Access HD 2.0 Court': '2MD7',
    'Access HD 2.0 Long': '2MD11',
    'Access HD 3.0': 'EDPlus9',
    'Access HD 3.0 Court': 'EDPlus7',
    'Access HD 3.0 Long': 'EDPlus11',
    'Access HD AF 0.37': 'ACOMODA .37',
    'Access HD AF 0.50': 'Mild Add .50',
    'Access HD AF 0.62': 'ACOMODA .62',
    'Access HD AF 0.75': 'Mild Add .75',
    'Access HD AF 0.87': 'ACOMODA .87',
    'Access HD AF 1.00': 'ACOMODAII 1.00',
    'Access HD Office Bureau': 'Office-2m',
    'Access HD Office Mobile': 'Office-1m',
    'Access HD Office Réunions': 'Office-4m',
    'Access HD Office II Bureau': 'Office-5m',
    'Access HD Office II Mobile': 'Office-3m',
    'Access HD Office II Réunions': 'Office-6m',
    'Access HD SV': 'FreeForm SV',
    'Access MYO Control': 'MyoEase SV',
    'Bifocal Executif': 'Executive',
    'BiFocal ST25': 'FT 25',
    'BiFocal ST28': 'FT 28',
    'BiFocal ST35': 'FT 35',
    'BiFocal ST45': 'FT 45',
    'BiFocal RD24': 'Round 24',
    'BiFocal RD28': 'Round 28',
    'BiFocal RD38': 'Round 38',
    'Lentilles Clients': null,
    'Révolution 4K': 'DFE9',
    'Révolution 4K Court': 'DFE7',
    'Révolution 4K Long': 'DFE11',
    'Révolution HD': 'DFM9',
    'Révolution HD Court': 'DFM7',
    'Révolution HD Long': 'DFM11',
    'Révolution HD Office': 'Office-',
    'Révolution UHD': 'EDPlus9',
    'Révolution UHD Court': 'EDPlus7',
    'Révolution UHD Long': 'EDPlus11',
    'Simple Vision HD': 'SV',
    'Simple Vision Stock': 'SV',
    'SV Offres Solaire': 'SV',
    'TriFocal 7X28': 'TriFocal 7X28',
    'TriFocal 8X35': 'TriFocal 8X35',
    'NU': 'UNC',
    'HC+1': 'UNC',
    'HC+2': 'HC',
    'HMC PLUS EC': 'HMC',
    'ULTRATRANS EC A/R': 'Super HMC',
    'ULTRATRANS EC A/R (Résiduel Bleu)': 'Super HMC Blue',
    'ULTRATRANS A/R BLUE UV': 'Super HMC Blue',
    'ULTRATRANS A/R Sun UV': 'BHMC',
    'ULTRATRANS A/R ANTI-FOG': 'Anti-Fog HMC',
    'CR39': ['CR39', 'Clear lens'],
    'CR39 Filtre Bleu': ['CR39', 'UV++'],
    'CR39 Transitions Gris': ['CR39', 'Sunfilter-photo(Grey)'],
    'CR39 Transitions Brun': ['CR39', 'Sunfilter-photo(Brown)'],
    'CR39 Transitions Vert': ['CR39', 'Photochromic(Green)'],
    'CR39 Polarisée Gris': ['CR39', 'Polarized(Grey)'],
    'CR39 Polarisée Brun': ['CR39', 'Polarized(Brown)'],
    'CR39 Polarisée G-15': ['CR39', 'Polarized(G-15)'],
    'CR39 Polarisée Jaune': ['CR39', 'Polarized(Y-30)'],
    '1.56': ['1.56', 'Clear lens'],
    '1.56 Filtre Bleu': ['1.56', 'UV++'],
    '1.56 Transitions Gris': ['1.56', 'Photochromic(Grey)'],
    '1.56 Transitions Brun': ['1.56', 'Photochromic(Brown)'],
    '1.56 Transitions Vert': ['1.56', 'Photochromic(Green)'],
    '1.59': ['Polycarbonate', 'Clear lens'],
    '1.59 Filtre Bleu': ['Polycarbonate', 'UV++'],
    '1.59 Transitions Gris': ['Polycarbonate', 'Sunfilter-photo(Grey)'],
    '1.59 Transitions Brun': ['Polycarbonate', 'Sunfilter-photo(Brown)'],
    '1.59 Polarisée Gris': ['Polycarbonate', 'Polarized(Grey)'],
    '1.59 Polarisée Brun': ['Polycarbonate', 'Polarized(Brown)'],
    '1.59 Polarisée G-15': ['Polycarbonate', 'Polarized(G-15)'],
    '1.59 Polarisée Bleu': ['Polycarbonate', 'Polarized(Blue)'],
    '1.60': ['1.60', 'Clear lens'],
    '1.60 Filtre Bleu': ['1.60', 'UV++'],
    '1.60 Transitions Gris': ['1.60', 'Sunfilter-photo(Grey)'],
    '1.60 Transitions Brun': ['1.60', 'Sunfilter-photo(Brown)'],
    '1.60 Polarisée Gris': ['1.60', 'Polarized(Grey)'],
    '1.60 Polarisée Brun': ['1.60', 'Polarized(Brown)'],
    '1.60 Polarisée G-15': ['1.60', 'Polarized(G-15)'],
    '1.60 Polarisée Jaune': ['1.60', 'Polarized(Y-30)'],
    '1.67': ['1.67', 'Clear lens'],
    '1.67 Filtre Bleu': ['1.67', 'UV++'],
    '1.67 Transitions Gris': ['1.67', 'Sunfilter-photo(Grey)'],
    '1.67 Transitions Brun': ['1.67', 'Sunfilter-photo(Brown)'],
    '1.67 Polarisée Gris': ['1.67', 'Polarized(Grey)'],
    '1.67 Polarisée Brun': ['1.67', 'Polarized(Brown)'],
    '1.67 Polarisée G-15': ['1.67', 'Polarized(G-15)'],
    '1.67 Polarisée Jaune': ['1.67', 'Polarized(Y-30)'],
    '1.74': ['1.74', 'Clear lens'],
    '1.74 Filtre Bleu': ['1.74', 'UV++'],
    '1.74 Transitions Gris': ['1.74', 'Sunfilter-photo(Grey)'],
    '1.74 Transitions Brun': ['1.74', 'Sunfilter-photo(Brown)'],
    '1.74 Polarisée Gris': ['1.74', 'Polarized(Grey)'],
    "Teinte unie": "Solid",
    "Teinte dégradée": "GradientColor",
    'Gris': addValue ? `Grey-${parseInt(addValue).toString().padStart(2, '0')}%` : null,
    'Brun': addValue ? `Brown-${parseInt(addValue).toString().padStart(2, '0')}%` : null,
    'G-15': addValue ? `G-15-${parseInt(addValue).toString().padStart(2, '0')}%` : null,
    'Jaune': addValue ? `Yellow-${parseInt(addValue).toString().padStart(2, '0')}%` : null,
    'Bleu': addValue ? `Blue-${parseInt(addValue).toString().padStart(2, '0')}%` : null,
    'Rose': addValue ? `Pink-${parseInt(addValue).toString().padStart(2, '0')}%` : null,
    'Mauve': addValue ? `Purple-${parseInt(addValue).toString().padStart(2, '0')}%` : null,
    'Orange': addValue ? `Orange-${parseInt(addValue).toString().padStart(2, '0')}%` : null,
    'Conduite': addValue ? `Brown-${parseInt(addValue).toString().padStart(2, '0')}%` : null,
    'Simple vision stock over': 'SVSO',
    'Révolution Office 1m': 'RO1M',
    'Révolution Office 2m': 'RO2M',
    'Révolution Office 3m': 'RO3M',
    'Révolution Office 4m': 'RO4M',
    'Révolution Office 5m': 'RO5M',
    'Révolution Office 6m': 'RO6M'
  };
  if (treatment) {
    return treatmentConverter(treatment, value)
  }
  if (Object.keys(cases).includes(value)) {
    return cases[value]
  }
  else {

    return value
  }
}

function remarkOrder(order) {
  let remark = ''
  if (['Simple Vision Stock', 'SV Offres Solaire'].includes(order.product.lens.design?.OD)) {
    remark += 'STOCK'
  }
  if ('ULTRATRANS A/R BLUE UV' === order.product.lens.treatment) {
    remark += ' UV++'
  }
  if ('Conduite' === order.product.lens.shade?.color) {
    remark += ' Brown/orange Tint'
  }
  remark = remark.trim()
  return remark
}
function exportExcel(orders) {

  // Convert base64 data to binary
  const binaryData = atob(base64Data);
  const data = new Uint8Array(binaryData.length);

  for (let i = 0; i < binaryData.length; i++) {
    data[i] = binaryData.charCodeAt(i);
  }

  let templateWorkbook = read(data, { type: 'array' });

  let newRowNumber = 4;

  let worksheet = templateWorkbook.Sheets[templateWorkbook.SheetNames[0]];

  for (const order of orders) {
    const newRowData = [
      [order.product.rx?.name, null, null, null, frameTypeConverter(order.product.lens.thickness, order.product.frame.type, order.product.lens.index), null, null,
      order.product.frame.dimensions.A, order.product.frame.dimensions.B, order.product.frame.dimensions.ED, order.product.frame.dimensions.bridge, null,
        null, null, null, null, order.product.rx.SPH.OD, order.product.rx.SPH.OS,
      order.product.rx.CYL.OD, order.product.rx.CYL.OS, order.product.rx.AXE.OD, order.product.rx.AXE.OS,
      order.product.rx.ADD.OD, order.product.rx.ADD.OS, order.product.lens?.PDdistant?.OD, order.product.lens?.PDdistant?.OS,
      order.product.lens?.PDclose?.OD, order.product.lens?.PDclose?.OS, order.product.lens?.COheight?.OD, order.product.lens?.COheight?.OS,
        null, null,
      (parseFloat(order.product.rx.prisme1.OD) != 0) ? order.product.rx.prisme1.OD : null, (parseFloat(order.product.rx.prisme1.OS) != 0) ? order.product.rx.prisme1.OS : null,
      (parseFloat(order.product.rx.prisme1.OD) != 0) ? converterExport(order.product.rx.IO.OD) : null, (parseFloat(order.product.rx.prisme1.OS) != 0) ? converterExport(order.product.rx.IO.OS) : null,
      (parseFloat(order.product.rx.prisme2.OD) != 0) ? order.product.rx.prisme2.OD : null, (parseFloat(order.product.rx.prisme2.OS) != 0) ? order.product.rx.prisme2.OS : null,
      (parseFloat(order.product.rx.prisme2.OD) != 0) ? converterExport(order.product.rx.UD.OD) : null, (parseFloat(order.product.rx.prisme2.OS) != 0) ? converterExport(order.product.rx.UD.OS) : null,
      (order.product.lens.curve.curve == 'Forcer') ? order.product.lens.curve.OD : null, (order.product.lens.curve.curve == 'Forcer') ? order.product.lens.curve.OS : null,

        null, null, null, null, null, null, null, null, null, null, null, null,
      converterExport(order.product.lens.index)[0],
      converterExport(order.product.lens.index)[1],
      converterExport(order.product.lens.design.OD),
      (order.product?.lens?.mirrorOptions?.exists) ? converterExport(order.product.lens.treatment, false, order.product?.lens?.mirrorOptions?.type) : converterExport(order.product.lens.treatment),
      (order.product.lens.shade?.type) ? converterExport(order.product.lens.shade?.type) : null,
      (order.product.lens.shade?.intensity) ? converterExport(order.product.lens.shade?.color, order.product.lens.shade.intensity) : null,
      (order.product.lens.shade?.intensitybis) ? converterExport(order.product.lens.shade?.color, order.product.lens.shade?.intensitybis) : null,
        null, null,
      remarkOrder(order)
      ]
    ];
    utils.sheet_add_aoa(worksheet, newRowData, { origin: newRowNumber - 1 });
    newRowNumber += 1
  }
  // Generate the updated Excel data
  const updatedDataURL = write(templateWorkbook, { bookType: 'xls', type: 'base64' });

  // Convert the base64 data to binary for Blob creation
  const updatedBinaryData = atob(updatedDataURL);
  const updatedData = new Uint8Array(updatedBinaryData.length);

  for (let i = 0; i < updatedBinaryData.length; i++) {
    updatedData[i] = updatedBinaryData.charCodeAt(i);
  }
  // Create a Blob from the binary data
  const updatedBlob = new Blob([updatedData], {
    type: 'application/vnd.ms-excel;charset=utf-8',
  });

  // Create a URL for the updated Blob
  const updatedUrl = URL.createObjectURL(updatedBlob);

  // Create an anchor element for downloading the updated Excel file
  const a = document.createElement('a');
  a.href = updatedUrl;
  // Create a new Date object
  const now = new Date();

  // Get date components
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const datetimeString = `${year}-${month}-${day}_${hours}h${minutes}m${seconds}s`;

  a.download = `${datetimeString}_ExportData.xls`;

  // Trigger a click event to start the download
  a.click();

  // Revoke the URL to release resources
  URL.revokeObjectURL(updatedUrl);
}

function exportExcel2(orders, targetMonthYear, userCode) {
  // Convert base64 data to binary
  const binaryData = atob(base64Data2);
  const data = new Uint8Array(binaryData.length);

  for (let i = 0; i < binaryData.length; i++) {
    data[i] = binaryData.charCodeAt(i);
  }

  let templateWorkbook = read(data, { type: 'array' });

  let newRowNumber = 15;

  let worksheet = templateWorkbook.Sheets[templateWorkbook.SheetNames[0]];

  utils.sheet_add_aoa(worksheet, [[null, null, null, null, null, null, null, targetMonthYear]]
    , { origin: 1 });
  let sumPrice = 0
  for (const order of orders) {
    const price = `${(order?.price === 'NaN') ? 0 : order.price}$`
    sumPrice += (order?.price === 'NaN') ? 0 : parseFloat(order.price)
    const newRowData = [
      [order._id, order?.product?.rx?.name, order?.uniqueKey, parseDateTime2(order?.created), parseDateTime2(order?.expedition), price.replace('.', ','), null, null, price.replace('.', ',')]
    ];
    utils.sheet_add_aoa(worksheet, newRowData, { origin: newRowNumber - 1 });
    newRowNumber += 1
  }
  const formattedSumPrice = `${sumPrice.toFixed(2)}$`.replace('.', ',');
  utils.sheet_add_aoa(worksheet, [[null, null, null, null, null, null, null, null, formattedSumPrice]]
    , { origin: 7 });
  utils.sheet_add_aoa(worksheet, [[orders[0].user?.code]]
    , { origin: 8 });
  utils.sheet_add_aoa(worksheet, [[orders[0].user?.name]]
    , { origin: 9 });
  utils.sheet_add_aoa(worksheet, [[orders[0].user?.address, null, null, null, null, null, null, null, formattedSumPrice]]
    , { origin: 10 });
  // Generate the updated Excel data
  const updatedDataURL = write(templateWorkbook, { bookType: 'xls', type: 'base64' });

  // Convert the base64 data to binary for Blob creation
  const updatedBinaryData = atob(updatedDataURL);
  const updatedData = new Uint8Array(updatedBinaryData.length);

  for (let i = 0; i < updatedBinaryData.length; i++) {
    updatedData[i] = updatedBinaryData.charCodeAt(i);
  }
  // Create a Blob from the binary data
  const updatedBlob = new Blob([updatedData], {
    type: 'application/vnd.ms-excel;charset=utf-8',
  });

  // Create a URL for the updated Blob
  const updatedUrl = URL.createObjectURL(updatedBlob);

  // Create an anchor element for downloading the updated Excel file
  const a = document.createElement('a');
  a.href = updatedUrl;
  // Create a new Date object

  a.download = `${targetMonthYear}_${userCode}_Export_Etat_de_Compte.xls`;

  // Trigger a click event to start the download
  a.click();

  // Revoke the URL to release resources
  URL.revokeObjectURL(updatedUrl);
}
const InputDropDownMenu = (
  { setState, value,
    options, width = '200px' }
) => {
  return <Input
    type="select"
    value={value?.name ? value.name : value}
    onChange={(e) => {
      const selectedValue = options.find((option) => option.name === e.target.value);
      setState((selectedValue) ? selectedValue : e.target.value);
    }}
    style={{ width: width, fontSize: 'small' }}
  >
    {options.map((option, index) => (
      <option key={index} value={option?.name ? option.name : option}>
        {option?.name ? option.name : option}
      </option>
    ))}
  </Input>

}
function StyledCheckbox({ label, checked, onChange }) {
  return (
    <FormGroup check>
      <Label check>
        <Input type="checkbox" checked={checked} onChange={onChange} />
      </Label>
    </FormGroup>
  );
}


const orderStatus = ['En Production/Non Taillée', 'En Production/Monture Tracée', 'En Production/Clip Fabrication', 'En Production/Monture en Attente', 'En Finition', 'En Production/Monture Reçu', 'Stock / Monture en attente', 'État de compte', 'Expédition', 'Taillage/Montage', 'En production', 'En cours', 'En attente', 'Monture en attente', 'Vérification en cours']
const Admin = () => {
  const [orders, setOrders] = useState([]);
  const [formattedMonthsAndYear, setFormattedMonthsAndYear] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [search, setSearch] = useState();
  const [inputScan, setInputScan] = useState();
  const [selectedStatus, setSelectedStatus] = useState(orderStatus[0]);
  const [searchParams] = useSearchParams();
  const [pageIndex, setPageIndex] = useState(0);
  const [limit, setLimit] = useState(searchParams.get('limit') ? searchParams.get('limit') : 40);
  const [totalPageCount, setTotalPageCount] = useState();
  const [modal, setModal] = useState(false);
  const [modalPricing, setModalPricing] = useState(false);
  const [modalDate, setModalDate] = useState(false);
  const [modalPricingData, setModalPricingData] = useState(null);
  const [modalDateData, setModalDateData] = useState(null);
  const [generatedFile, setGeneratedFile] = useState();
  const [generatedHtmlFile, setGeneratedHtmlFile] = useState('');
  const [docx, setDocx] = useState();
  const docs = [{uri: require("../assets/pdf-test.pdf")}]
  const viewerRef = useRef(null)

  const callView = async () => {
    WebViewer(
        {
          licenseKey: 'demo:k.ashrf911@gmail.com:7e71b4220200000000330d47a06d805e15c0d2fd796b09c85c9359c34a',
          path: 'lib',
          initialDoc: generatedFile,
          extension: 'docx',
          isReadOnly: true
        },
        viewerRef.current,
    ).then((instance) => {
      const { documentViewer, annotationManager, Tools, Annotations } = instance.Core;
      // Disable editing
      instance.UI.disableElements([
        'annotationPopup',
        'annotationNoteConnectorLine',
        'annotationNote',
        'annotationStylePopup',
        'textPopup',
        'contextMenuPopup',
        'textSelectButton',
        'panToolButton',
        'linkButton',
        'highlightToolGroupButton',
        'annotationToolsButton',
        'toolsHeader',
        'toolbarGroup-Annotate',
        'toolbarGroup-Edit',
        'toolsButton',
        'menuButton',
        'rubberStampToolGroupButton',
        'signatureToolButton',
        'stampToolButton',
        'stickyToolButton',
        'richTextPopup',
        'textUnderlineToolButton',
        'textStrikeoutToolButton',
        'freeTextToolButton',
        'stickyNoteToolButton',
        'contextMenuPopup',
        'marqueeToolButton'
      ]);
    });
  }

  async function generateFile(order) {
    const content = await loadLocalFile();
    const InspectModule = require("docxtemplater/js/inspect-module");
    const iModule = InspectModule();
    var zip = new PizZip(content);
    var doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
      modules: [iModule]
    });
    const tags = iModule.getAllTags();

    for (const key in tags) {
      tags[key] = getObjectValue(order, key);
    }

    if (order?.product?.rx?.OD == "Ne pas commander" || order?.product?.rx?.OS == "Ne pas commander") {
      if (Object.keys(tags).includes('product.lens.design.OS')) {
        tags['product.lens.design.OS'] = ''
      }
    }

    if (Object.keys(tags).includes('expedition')) {
      tags['expedition'] = new Date(order?.expedition).toLocaleDateString('en-CA');
    }
    if (Object.keys(tags).includes('created')) {
      tags['created'] = new Date(order?.created).toLocaleDateString('en-CA');
    }
    tags['detailedprice.design'] = parseFloat(tags['detailedprice.design'] ).toFixed(2);
    tags['detailedprice.designbis'] = parseFloat(tags['detailedprice.designbis'] ).toFixed(2);
    tags['detailedprice.frameType'] = parseFloat(tags['detailedprice.frameType'] ).toFixed(2);
    tags['detailedprice.tailler'] = parseFloat(tags['detailedprice.tailler'] ).toFixed(2);
    tags['detailedprice.curve'] = parseFloat(tags['detailedprice.curve'] ).toFixed(2);
    tags['detailedprice.shade'] = parseFloat(tags['detailedprice.shade'] ).toFixed(2);
    tags['detailedprice.prisme'] = parseFloat(tags['detailedprice.prisme'] ).toFixed(2);
    tags['detailedprice.mirror'] = parseFloat(tags['detailedprice.mirror'] ).toFixed(2);
    tags['detailedprice.manutention'] = parseFloat(tags['detailedprice.manutention'] ).toFixed(2);
    tags['detailedprice.extraOption'] = parseFloat(tags['detailedprice.extraOption'] ).toFixed(2);

    console.log(tags)
    doc.setData(tags);

    try {
      doc.render();
    } catch (error) {

      console.error('Error rendering document:', error);
      return;
    }

    var out = doc.getZip().generate({
      type: 'blob',
      mimeType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });

    // Create a URL for the generated file and set it to state
    const blobUrl = URL.createObjectURL(out);

    setGeneratedFile(blobUrl)
  }

  const toggle = async (order) =>{
    const test = await generateFile(order);
     setModal(!modal)
   };
   const togglePricing = (order) => {
     if(modalPricing){
       setModalPricingData(null)
     }else{
       setModalPricingData(order)
     }

     return setModalPricing(!modalPricing)
   };


  const toggleDate = (order) => {
    if(modalDate){
      setModalDateData(null)
    }else{
      setModalDateData(order)
    }

    return setModalDate(!modalDate)
  };

  const submitPricing = (order) => {
    let req_body = {
      userId: client,
      // created: moment(date).tz(userTimezone).toISOString(),
      // created: strDate,
      created: date,
      product: {
        instructions: order.product.instructions,
        rx: order.product.instructions,
        lens: order.product.instructions,
        frame: order.product.instructions
      }
    }
  };


  const [isScanOn, setIsScanOn] = useState(false);
  const fetchOrders = async (params) => {
    try {
      const pagingInfo = (params?.pagingInfo) ?  params?.pagingInfo.selected + 1 :  1;
      const keyword = (params?.keyword) ?  params?.keyword :  '';
      const bearer = 'Bearer ' + localStorage.getItem('token');
      const filters = {
        search : keyword,
        selectedMonth,
        selectedUser,
        selectedStatus,
        inputScan,
        pageIndex: pagingInfo,
        limit: 40
      }
      const response = await axios.get(baseUrl + "order/order/view",
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': bearer
        },
        params: filters
      })

      let data = await response.data.docs;
      setOrders(data);
      setListMonths(response.data.formattedDates);
      setSelectedMonth(response.data.formattedDates[0])
      setTotalPageCount(response.data.totalPages);
    } catch (error) {
      console.log("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchOrders()
  }, [])

  useEffect(() => {
    try {
      setFilteredOrders(orders)
    }
    catch (e) {
      console.log('ERROR', e);
    }
  }, [search, orders])

  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null)
  const [listMonths, setListMonths] = useState([])
  const [listUsers, setListUsers] = useState([])
  const [searchKey , setSearchKey] = useState(null)
  // useEffect(() => {
  //   setListMonths(f)
  //   const uniqueMonths = Array.from(new Set(orders.map(order => {
  //     if (!order?.expedition) {
  //       return null
  //     }
  //     const dateObject = new Date(order.expedition);
  //     const monthYearString = `${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getFullYear()}`;
  //     return monthYearString;
  //   }))).filter(monthYear => monthYear !== null)

  //   console.log(uniqueMonths)
  //   setListMonths(uniqueMonths);
  //   setSelectedMonth(uniqueMonths[0])
  // }, [orders])

  const fetchOrdersByDate = async (selectedDate) => {
    const bearer = 'Bearer ' + localStorage.getItem('token');

    const response = await axios.get(baseUrl + 'order/order/user/date',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': bearer
      },
      params: {
        selectedDate
      }
    })

    return response.data
  }

  useEffect(() => {
    if(selectedMonth){
      const userOrders = fetchOrdersByDate(selectedMonth).then(data => {
        setListUsers(data);
        setSelectedUser(data[0])
      })

    }

    // const uniqueUsers = Array.from(new Set(orders.filter((order) => {
    //   const dateObject = new Date(order.expedition);
    //   const monthYearString = `${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getFullYear()}`;
    //   return monthYearString === selectedMonth;
    // }).map(order =>  order?.user?.code)))
    //   .map(code => orders.find(order => order?.user?.code === code).user);
    // setListUsers(uniqueUsers);
    // setSelectedUser(uniqueUsers[0])
  }, [selectedMonth])

  const handleSubmitDate = () => {
    let req_body = {
      orderId: modalDateData.id,
      expedition: modalDateData.expedition,
    }
    const bearer = 'Bearer ' + localStorage.getItem('token');

    fetch(baseUrl + 'order/expedition', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': bearer
      },
      body: JSON.stringify(req_body),
    })
      .then((response) => response.json())
      .then(response => {
        if (response.success) {
          alert('La commande a bien été modifié')
          window.location.reload();
        }
        else {
          var error = new Error('Error ' + response);
          throw error;
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });

  }
  const handleSubmit = () => {
    const newDetailedPrice = modalPricingData.detailedprice

    console.log(newDetailedPrice);
    Object.keys(newDetailedPrice).forEach(key => {
      newDetailedPrice[key] = Number(newDetailedPrice[key]);
      // if(newDetailedPrice[key] === undefined || newDetailedPrice[key] === null){
      //   newDetailedPrice[key] = 0
      // }
    })
    let req_body = {
      orderId: modalPricingData.id,
      detailedprice: newDetailedPrice,
      price: Object.entries(newDetailedPrice)
      .filter(([key, value]) => key !== 'nb')
      .reduce((acc, [key, value]) => acc + value, 0)
    }
    const bearer = 'Bearer ' + localStorage.getItem('token');

    fetch(baseUrl + 'order/priceDetails', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': bearer
      },
      body: JSON.stringify(req_body),
    })
      .then((response) => response.json())
      .then(response => {
        if (response.success) {
          alert('La commande a bien été modifié')
          window.location.reload();
        }
        else {
          var error = new Error('Error ' + response);
          throw error;
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };


  const handleRowClick = (row , optionalId) => {
    if(row){
      if (selectedRows.includes(row)) {
        setSelectedRows(selectedRows.filter((selectedRow) => selectedRow !== row));
      } else {
        setSelectedRows([...selectedRows, row]);
      }
    }else{
      if (selectedRows.includes(optionalId)) {
        setSelectedRows(selectedRows.filter((selectedRow) => selectedRow !== optionalId));
      } else {
        setSelectedRows([...selectedRows, optionalId]);
      }
    }

  };

  const modifyStatus = async () => {
    if (selectedRows.length > 0) {
      //const orderIds = selectedRows.map((index) => filteredOrders[index]._id);

      try {
        const bearer = 'Bearer ' + localStorage.getItem('token');
        const updateData = { status: selectedStatus };

        const response = await fetch(baseUrl + 'order/orders', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': bearer,
          },
          body: JSON.stringify({ orderBarCodes : selectedRows, updateData }),
        });
        if (response.ok) {
          fetchOrders({keyword : searchKey})
        } else {
          console.log('Server returned an error:', response.status, response.statusText);
        }
      } catch (error) {
        console.log('Error fetching or updating orders:', error);
      }
    } else {
      alert('Veuillez sélectionner les commandes à modifier.');
    }
  };

  const exportSelectedRowsExcel = async () => {
    if (selectedRows.length > 0) {
      const bearer = 'Bearer ' + localStorage.getItem('token');
      const response = await axios.get(baseUrl + "order/order/export",
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': bearer
        },
        params: {
          barcodes : selectedRows,
          edit: true
        }
      })

      //const filteredElements = filteredOrders.filter((element, index) => selectedRows.includes(element.barcode));
      //exportExcel(filteredElements)
      exportExcel(response.data)
      fetchOrders({keyword : searchKey})

    }
    else { alert('Veuillez sélectionner les commandes à exporter.') }
  }


  const exportCommandMonthExcel = async () => {
    let targetMonthYear = selectedMonth

    const bearer = 'Bearer ' + localStorage.getItem('token');

    const response = await axios.get(baseUrl + 'order/order/get/account/state',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': bearer
      },
      params:{
        selectedDate: targetMonthYear,
        userId : selectedUser?._id
      }
    })

    if (orders.length > 0) {
      const filteredElements = response.data
      // const filteredElements = orders.filter((order, index) => {
      //   const [targetMonth, targetYear] = targetMonthYear.split('-');

      //   const orderExpedition = new Date(order?.expedition);
      //   const orderMonth = (orderExpedition.getMonth() + 1).toString().padStart(2, '0');
      //   const orderYear = orderExpedition.getFullYear().toString();

      //   return orderMonth === targetMonth && orderYear === targetYear && order.user?.code === selectedUser?.code;
      // });
      if (filteredElements?.length > 0) {
        exportExcel2(filteredElements, targetMonthYear, selectedUser?.code);
      }
      else {
        alert('Pas de commande à exporter.');
      }
    } else {
      alert('Pas de commande à exporter.');
    }
  }

  const appendBinaryDataToArray = (data, dataArray) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function () {
        dataArray.push(new Uint8Array(this.result));
        resolve();
      };
      reader.readAsArrayBuffer(data);
    });
  };
  async function handleFileChangeSelection(event) {
    if (selectedRows.length > 0) {
      const bearer = 'Bearer ' + localStorage.getItem('token');
      const response = await axios.get(baseUrl + "order/order/export",
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': bearer
        },
        params: {
          barcodes : selectedRows
        }
      })

      const filteredElements = response.data
      // const filteredElements = filteredOrders.filter((element, index) => selectedRows.includes(element.barcode) || selectedRows.includes(element._id));
      const selectedFiles = event.target.files;

      if (!selectedFiles || selectedFiles.length === 0) {
        alert('Please select DOC files.');
        return;
      }
      for (const selectedFile of selectedFiles) {
        let combinedDoc = []
        for (const [orderIndex, order] of filteredElements.entries()) {

          if(order.product){
            await new Promise(async (resolve, reject) => {
              loadFile(selectedFile, async (error, content) => {
                console.log('CONTENT2', content);
                if (error) {
                  console.error('Error reading file:', error);
                  return;
                }
                const InspectModule = require("docxtemplater/js/inspect-module");
                const iModule = InspectModule();
                var zip = new PizZip(content);
                var doc = new Docxtemplater(zip, {
                  paragraphLoop: true,
                  linebreaks: true,
                  modules: [iModule]
                });
                const tags = iModule.getAllTags();

                for (const key in tags) {
                  tags[key] = getObjectValue(order, key);
                }

                if (order.product.rx.OD == "Ne pas commander" || order.product.rx.OS == "Ne pas commander") {
                  if (Object.keys(tags).includes('product.lens.design.OS')) {
                    tags['product.lens.design.OS'] = ''
                  }
                }

                if (Object.keys(tags).includes('expedition')) {
                  tags['expedition'] = new Date(order?.expedition).toLocaleDateString('en-CA');
                }
                if (Object.keys(tags).includes('created')) {
                  tags['created'] = new Date(order?.created).toLocaleDateString('en-CA');
                }
                tags['detailedprice.design'] = parseFloat(tags['detailedprice.design'] ).toFixed(2);
                tags['detailedprice.designbis'] = parseFloat(tags['detailedprice.designbis'] ).toFixed(2);
                tags['detailedprice.frameType'] = parseFloat(tags['detailedprice.frameType'] ).toFixed(2);
                tags['detailedprice.tailler'] = parseFloat(tags['detailedprice.tailler'] ).toFixed(2);
                tags['detailedprice.curve'] = parseFloat(tags['detailedprice.curve'] ).toFixed(2);
                tags['detailedprice.shade'] = parseFloat(tags['detailedprice.shade'] ).toFixed(2);
                tags['detailedprice.prisme'] = parseFloat(tags['detailedprice.prisme'] ).toFixed(2);
                tags['detailedprice.mirror'] = parseFloat(tags['detailedprice.mirror'] ).toFixed(2);
                tags['detailedprice.manutention'] = parseFloat(tags['detailedprice.manutention'] ).toFixed(2);
                tags['detailedprice.extraOption'] = parseFloat(tags['detailedprice.extraOption'] ).toFixed(2);
                doc.setData(tags);


                try {
                  doc.render();
                } catch (error) {

                  console.error('Error rendering document:', error);
                  return;
                }
                const docxData = doc.getZip().generate({
                  type: 'blob',
                  mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                });

                await appendBinaryDataToArray(docxData, combinedDoc)

                // combinedDoc.loadZip(doc.getZip());
                // const docContent = doc.getZip().generate({ type: 'nodebuffer' });
                // combinedDoc.loadZip(new PizZip(docContent));
                resolve();
              });
            });
          }
        }
        // var out = combinedDoc.getZip().generate({
        //   type: 'blob',
        //   mimeType:
        //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        // });
        var out = new DocxMerger({}, combinedDoc);
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const datetimeString = `${year}-${month}-${day}_${hours}h${minutes}m${seconds}s`;

        out.save('blob', data => {
          // Save the Blob using FileSaver.js
          saveAs(data, `${datetimeString}-${selectedFile.name.replace(/\.[^/.]+$/, '')}.docx`);
        });
        // saveAs(out, `${datetimeString}-${selectedFile.name.replace(/\.[^/.]+$/, '')}.docx`);

      }
      event.target.value = ""

    }
    else { alert('Veuillez sélectionner les commandes à exporter.') }
  }
  return (
    <div className='adminContainer'>
      <Modal isOpen={modal} toggle={toggle} fullscreen onOpened={() => callView()}>
        <ModalHeader toggle={toggle}>Facture</ModalHeader>
        <ModalBody id='modal_body'>
          <div className={'previewDoc'} ref={viewerRef} style={{ width: '100%', height: '100vh' }}></div>
          {/* <div dangerouslySetInnerHTML={{__html: generatedHtmlFile}}></div> */}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Fermer
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalDate} toggle={toggleDate}>
        <ModalHeader toggle={toggleDate}>Modifier la date d'expédition</ModalHeader>
        <ModalBody>
          <DateTimePicker  onChange={(e) => setModalDateData((currState) => ({
                  ...currState,
                  expedition: new Date(e)
                }))} value={modalDateData?.expedition}/>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmitDate}>
            Valider
          </Button>{' '}
          <Button color="secondary" onClick={toggleDate}>
            Annuler
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalPricing} toggle={togglePricing} size='lg'>
        <ModalHeader toggle={togglePricing}>Modifier les détails du prix</ModalHeader>
        <ModalBody>
          <Row className='mt-2'>
            <Col xs="3">
              <Label>Design</Label>
            </Col>
            <Col xs="9">
              <InputGroup>
                <Input type="number" min="0" onChange={(e) => setModalPricingData((currState) => ({
                  ...currState,
                  detailedprice:{
                    ...currState.detailedprice,
                    design: parseFloat(Number(e.target.value))
                  }
                }))} value={modalPricingData?.detailedprice?.design}/>
              </InputGroup>
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col xs="3">
              <Label>Designbis</Label>
            </Col>
            <Col xs="9">
              <InputGroup>
                <Input type="number" min="0" onChange={(e) => setModalPricingData((currState) => ({
                  ...currState,
                  detailedprice:{
                    ...currState.detailedprice,
                    designbis: parseFloat(Number(e.target.value))
                  }
                }))} value={modalPricingData?.detailedprice?.designbis}/>
              </InputGroup>
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col xs="3">
              <Label>Tailler</Label>
            </Col>
            <Col xs="9">
              <InputGroup>
                <Input type="number" min="0" onChange={(e) => setModalPricingData((currState) => ({
                  ...currState,
                  detailedprice:{
                    ...currState.detailedprice,
                    tailler: parseFloat(Number(e.target.value))
                  }
                }))} value={modalPricingData?.detailedprice?.tailler} />
              </InputGroup>
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col xs="3">
              <Label>frameType</Label>
            </Col>
            <Col xs="9">
              <InputGroup>
                <Input type="number" min="0" onChange={(e) => setModalPricingData((currState) => ({
                  ...currState,
                  detailedprice:{
                    ...currState.detailedprice,
                    frameType: parseFloat(Number(e.target.value))
                  }
                }))}  value={modalPricingData?.detailedprice?.frameType}/>
              </InputGroup>
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col xs="3">
              <Label>shade</Label>
            </Col>
            <Col xs="9">
              <InputGroup>
                <Input type="number" min="0" onChange={(e) => setModalPricingData((currState) => ({
                  ...currState,
                  detailedprice:{
                    ...currState.detailedprice,
                    shade: parseFloat(Number(e.target.value))
                  }
                }))} value={modalPricingData?.detailedprice?.shade}/>
              </InputGroup>
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col xs="3">
              <Label>mirror</Label>
            </Col>
            <Col xs="9">
              <InputGroup>
                <Input type="number" min="0" onChange={(e) => setModalPricingData((currState) => ({
                  ...currState,
                  detailedprice:{
                    ...currState.detailedprice,
                    mirror: parseFloat(Number(e.target.value))
                  }
                }))} value={modalPricingData?.detailedprice?.mirror} />
              </InputGroup>
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col xs="3">
              <Label>prisme</Label>
            </Col>
            <Col xs="9">
              <InputGroup>
                <Input type="number" min="0" onChange={(e) => setModalPricingData((currState) => ({
                  ...currState,
                  detailedprice:{
                    ...currState.detailedprice,
                    prisme: parseFloat(Number(e.target.value))
                  }
                }))}  value={modalPricingData?.detailedprice?.prisme}/>
              </InputGroup>
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col xs="3">
              <Label>curve</Label>
            </Col>
            <Col xs="9">
              <InputGroup>
                <Input type="number" min="0" onChange={(e) => setModalPricingData((currState) => ({
                  ...currState,
                  detailedprice:{
                    ...currState.detailedprice,
                    curve: parseFloat(Number(e.target.value))
                  }
                }))} value={modalPricingData?.detailedprice?.curve}/>
              </InputGroup>
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col xs="3">
              <Label>CYL</Label>
            </Col>
            <Col xs="9">
              <InputGroup>
                <Input type="number" min="0" onChange={(e) => setModalPricingData((currState) => ({
                  ...currState,
                  detailedprice:{
                    ...currState.detailedprice,
                    CYL: parseFloat(Number(e.target.value))
                  }
                }))} value={modalPricingData?.detailedprice?.CYL}/>
              </InputGroup>
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col xs="3">
              <Label>ADD</Label>
            </Col>
            <Col xs="9">
              <InputGroup>
                <Input type="number" min="0" onChange={(e) => setModalPricingData((currState) => ({
                  ...currState,
                  detailedprice:{
                    ...currState.detailedprice,
                    ADD: parseFloat(Number(e.target.value))
                  }
                }))} value={modalPricingData?.detailedprice?.ADD} />
              </InputGroup>
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col xs="3">
              <Label>Manutention</Label>
            </Col>
            <Col xs="9">
              <InputGroup>
                <Input type="number" min="0" onChange={(e) => setModalPricingData((currState) => ({
                  ...currState,
                  detailedprice:{
                    ...currState.detailedprice,
                    manutention: parseFloat(Number(e.target.value))
                  }
                }))} value={modalPricingData?.detailedprice?.manutention} />
              </InputGroup>
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col xs="3">
              <Label>Option extra</Label>
            </Col>
            <Col xs="9">
              <InputGroup>
                <Input type="number" min="0" onChange={(e) => setModalPricingData((currState) => ({
                  ...currState,
                  detailedprice:{
                    ...currState.detailedprice,
                    extraOption: parseFloat(Number(e.target.value))
                  }
                }))} value={modalPricingData?.detailedprice?.extraOption}/>
              </InputGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Valider
          </Button>
          <Button color="secondary" onClick={togglePricing}>
            Annuler
          </Button>
        </ModalFooter>
      </Modal>


      <Input type="file" id={'docInputSELECTION'} accept=".docx" onChange={(e) => handleFileChangeSelection(e)} style={{ display: 'none' }} multiple />
      <div className='adminHeader'>Liste des commandes</div>
      <div className='adminSearch'>
        <Input
          type="text"
          className='adminSearchInput'
          placeholder="Recherche"
          onChange={(e) => {
            //if (e.target.value?.length > 2 && orders?.length > 0) {
            if (e.target.value?.length > 1) {
              setSearchKey(e.target.value)
              fetchOrders({keyword: e.target.value});
              // const search_words = search.toLowerCase().match(/[a-zA-Z0-9]+/g)
              // const filteredData = orders.filter((item) => {
              //   return search_words.every(w => JSON.stringify(Object.values(item)).toLowerCase().includes(w))
              // })
              // setFilteredOrders(filteredData)
            } else {
              fetchOrders({pagingInfo : {selected : pageIndex}});
              // setPageIndex(0)
            }
            //setSearch(e.target.value)
          }}
          onPaste={(e) => {
            setSearch(e.clipboardData.getData('text'));
          }}
        />
        <div className='d-flex gap-1'>
          <Button className='button-header-order' onClick={exportSelectedRowsExcel}>Export sélection&nbsp;&nbsp; <SiMicrosoftexcel size='20' /></Button>
          <Button className='button-header-order' onClick={() => transformDoc('SELECTION')}>Export sélection&nbsp;&nbsp; <GrDocumentUser size='20' /></Button>
        </div>
      </div>
      <div className='adminSecondRow'>
        <div className=' d-flex align-items-center gap-2'>
          <div className='d-flex align-items-center' style={{ marginRight: '30px' }}>
            <div className='text-bold-yellow' style={{ marginRight: '10px', width: '100px' }}>Scan mode</div>
            <ToggleSwitch isOn={isScanOn}
              toggleSwitch={() => {
                setIsScanOn(!isScanOn)
              }} />
          </div>
          {isScanOn ?
            <div>
              <Input
                type="text"
                style={{ width: '100px', fontSize: 'small' }}
                placeholder="Scanner ici"
                id='inputScan'
                value={inputScan || ''}
                onChange={async (e) => {
                  const element = e.target.value
                  setInputScan(element)
                  if (element) {
                    if (element.length == 6) {
                      const bearer = 'Bearer ' + localStorage.getItem('token');
                      const response = await axios.get(baseUrl + "order/order/barcode/check",
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': bearer
                        },
                        params: {
                          barcode: element
                        }
                      })

                      if(response.data.length > 0){
                        setSelectedRows([...selectedRows, element]);
                        setInputScan(null)
                      }


                      // const inputScan = document.getElementById('inputScan')
                      // if (inputScan) {
                      //   inputScan.value = '';
                      // }
                      //const index = filteredOrders.findIndex((order) => order.barcode === element);
                      // if (index !== -1 && !selectedRows.includes(index)) {
                      //   setSelectedRows([...selectedRows, index]);
                      //   const inputScan = document.getElementById('inputScan')
                      //   if (inputScan) {
                      //     inputScan.value = '';
                      //   }
                      // }
                    }
                  }
                }}
                onPaste={(e) => {
                  const element = e.clipboardData.getData('text')
                  if (element) {
                    if (element.length == 6) {
                      setSelectedRows([...selectedRows, element]);
                      // const inputScan = document.getElementById('inputScan')
                      // if (inputScan) {
                      //   inputScan.value = '';
                      // }
                    }
                    // const index = filteredOrders.findIndex((order) => order.barcode === element);
                    // if (index !== -1 && !selectedRows.includes(index)) {
                    //   setSelectedRows([...selectedRows, index]);
                    //   const inputScan = document.getElementById('inputScan')
                    //   if (inputScan) {
                    //     inputScan.value = '';
                    //   }
                    // }
                  }

                }}
              />


            </div>
            :
            null
          }
          <div className='text-bold-yellow'>
            Statut
          </div>
          <InputDropDownMenu setState={setSelectedStatus} value={selectedStatus || ''}
            options={orderStatus} />
          <Button className='button-status-order' onClick={modifyStatus}>Modifier</Button>
        </div>
        <div className='d-flex'>
          <InputDropDownMenu setState={setSelectedMonth} value={selectedMonth || ''} width={"120px"}
            options={listMonths} />
          <InputDropDownMenu setState={setSelectedUser} value={selectedUser?.name || ''} width={"230px"}
            options={listUsers} />
          <Button className='button-header-order' style={{ fontWeight: '400', width: '200px' }} onClick={exportCommandMonthExcel}>Export Etat de compte&nbsp;&nbsp; <SiMicrosoftexcel size='15' /></Button>
        </div>
      </div>
      <div className='order-list-pagination'>
        <div>
          <Table striped responsive>
            <thead>
            <tr>
              <th>
                ID
              </th>
              <th>
                Numéro de facture
              </th>
              <th>
                Création
              </th>
              <th>
                Status
              </th>
              <th>
                Expédition
              </th>
              <th>
                Patient
              </th>
              <th>
                Compte
              </th>
              <th>
                Client
              </th>
              <th>
                Prix
              </th>
              <th>
                Export
              </th>
              <th col="2">
                Code Barre
              </th>
              <th onClick={() => setSelectedRows([])}
                style={{
                  fontSize: 'x-small'
                }}>
                {(selectedRows.length > 0) ? `(${selectedRows.length}) Décocher` : '(x) Sélectionner'}
              </th>
            </tr>
            </thead>
            <tbody>
              {filteredOrders.map((ele, index) => (
                <tr key={ele._id}>
                  <td style={{ fontWeight: '600', fontStyle: 'italic' }}>
                    {ele._id}
                  </td >
                  <td style={{ fontWeight: '600', fontStyle: 'italic' }}>
                    {ele.uniqueKey}
                  </td >
                  <td style={{ fontWeight: '500', color: '#434343', fontStyle: 'italic', color: '#694708' }}>
                    {parseDateTime(ele.created)}
                  </td >
                  <td style={{ fontWeight: '600', color: '#694708' }}>
                    {ele.status}
                  </td >
                  <td style={{ ffontWeight: '500', color: '#434343', fontStyle: 'italic', color: '#694708' }}>
                    {(ele.expedition) ? <div onClick={() => toggleDate(ele)}> {parseDateTime(ele.expedition)} </div> : 'Pas Expédié'}
                  </td >
                  <td style={{ fontWeight: '400' }}>
                    {(ele.product?.rx?.name) ? ele.product?.rx?.name : 'null'}
                  </td >
                  <td style={{ fontWeight: '400' }}>
                    {ele?.user?.code}
                  </td >
                  <td style={{ fontWeight: '400' }}>
                    {ele?.user?.name}
                  </td >
                  <td   style={{ fontWeight: '400' }}>
                    ${ele?.price === 'NaN' ? 0 : ele.price}

                  </td >
                  <td className="column-border button-export-column-border" style={{ color: '#694708', minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div style={{display: 'flex'}}>
                      <SiMicrosoftexcel size='20' onClick={() => { exportExcel([ele]) }} />
                      &nbsp;
                      &nbsp;
                      <Input type="file" id={`docInput${ele._id}`} accept=".docx" onChange={(e) => handleFileChange(e, ele)} style={{ display: 'none' }} multiple />
                      <GrDocumentUser size='20' color='#694708' onClick={() => { transformDoc(ele._id) }} />
                      &nbsp;
                      &nbsp;
                      <NavLink to="/commande" state={{orderId: ele._id}}>
                        <ImCopy size='20' color='#694708'/>
                      </NavLink>
                      &nbsp;
                      &nbsp;
                      <NavLink to="/commande" state={{orderId: ele._id , edit : true}}>
                      <FiEdit size='20' color='#694708'/>
                      </NavLink>
                      &nbsp;
                      &nbsp;
                      <NavLink onClick={() => toggle(ele)}>
                        <HiOutlineDocumentSearch size='20' color='#694708'/>
                       </NavLink>
                       &nbsp;
                      &nbsp;
                      <NavLink onClick={() => togglePricing(ele)}>
                        <MdOutlineCalculate size='20' color='#694708'/>
                       </NavLink>
                    </div>
                  </td >
                  <td col="2">
                    <div style={{display: 'flex'}}>
                      <Input id={`codebar_${ele._id}`} type='text' placeholder='vide' style={{ width: '80%', fontSize: 'x-small', height: '30px', textAlign: 'center' }} defaultValue={ele?.barcode ? ele.barcode : null} />
                      <Button
                      style={{ width: '10px', marginLeft: '10px', fontWeight: '900', fontSize: 'x-large', paddingBottom: '10px' }}
                      className='small-btn-column button-status-order'
                        onClick={async () => {
                          const element = document.getElementById(`codebar_${ele._id}`)?.value
                          if (element) {
                            try {
                              const bearer = 'Bearer ' + localStorage.getItem('token');
                              const updateData = { barcode: element };
                              const orderIds = [ele.id]
                              const response = await fetch(baseUrl + 'order/orders', {
                                method: 'PUT',
                                headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': bearer,
                                },
                                body: JSON.stringify({ orderIds, updateData }),
                              });
                              if (response.ok) {
                                fetchOrders()
                              } else {
                                console.log('Server returned an error:', response.status, response.statusText);
                              }
                            } catch (error) {
                              console.log('Error fetching or updating orders:', error);
                            }
                          }
                        }
                        }
                      >&#10003;</Button>
                    </div>
                  </td>
                  <td>
                    <StyledCheckbox label="Check me" checked={selectedRows.includes(ele.barcode) || selectedRows.includes(ele._id) ? true : false} onChange={() => handleRowClick(ele.barcode , ele._id)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {/* pageIndex 0  */}
        <div className='orders-pagination'>
          <ReactPaginate className="react-paginate"
              breakLabel="..."
              nextLabel="next >"
              onPageChange={(pagingInfo) => {setPageIndex(pagingInfo?.selected === 0 ? 1 : pagingInfo?.selected)
                return fetchOrders({pagingInfo})}}
              pageRangeDisplayed={5}
              pageCount={totalPageCount}
              forcePage={pageIndex}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
            />
        </div>
      </div>
    </div >
  );
};

export default Admin;
