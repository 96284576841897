import './css/Home.css';
import React from 'react';

const Home = () => {
  return (
    <div className='homeContainer'>
      <div className="stl_ stl_02">
        <div className="stl_view">
          <div className="stl_05 stl_06">
            <div className="stl_01" style={{ left: '8.125em', top: '12.0019em' }}>
              <span className="stl_12 stl_08 stl_09" style={{ wordSpacing: '-0em' }}>
                OLAB PLUS 30 ANS À VOTRE SERVICE! &nbsp;
              </span>
            </div>
            <div className="stl_01" style={{ left: '12.5375em', top: '19.3521em' }}>
              <span className="stl_13 stl_14 stl_09"></span>
            </div>
            <div className="stl_01" style={{ left: '29.5722em', top: '19.3521em' }}>
              <span className="stl_13 stl_14 stl_09"></span>
            </div>
            <div className="stl_01" style={{ left: '47.232em', top: '19.3521em' }}>
              <span className="stl_13 stl_14 stl_09"></span>
            </div>
            <div className="stl_01" style={{ left: '9.8701em', top: '22.4915em' }}>
              <span className="stl_15 stl_16 stl_09">S'ENREGISTRER GRATUITEMENT &nbsp;</span>
            </div>
            <div className="stl_01" style={{ left: '46.3827em', top: '22.4915em' }}>
              <span className="stl_15 stl_16 stl_09">SUIVI DE VOS COMMANDES &nbsp;</span>
            </div>
            <div className="stl_01" style={{ left: '29.3738em', top: '22.5311em' }}>
              <span className="stl_17 stl_08 stl_18" style={{ wordSpacing: '-0.019em' }}>
                COMMANDEZ EN LIGNE &nbsp;
              </span>
            </div>
            <div className="stl_01" style={{ left: '26.7222em', top: '24.3093em' }}>
              <a href="http://olab-plus.ca/commandez/" target="_blank">
                <span className="stl_19 stl_16 stl_09">Vous pouvez faire vos commandes en toute &nbsp;</span>
              </a>
            </div>
            <div className="stl_01" style={{ left: '26.7222em', top: '25.4701em' }}>
              <span className="stl_19 stl_16 stl_09">sécurité, en choisissant des produits de haute &nbsp;</span>
            </div>
            <div className="stl_01" style={{ left: '26.7222em', top: '26.6308em' }}>
              <span className="stl_19 stl_16 stl_09">qualité &nbsp;</span>
            </div>
            <div className="stl_01" style={{ left: '8.75em', top: '24.9343em' }}>
              <a href="http://olab-plus.ca/mon-compte/" target="_blank">
                <span className="stl_19 stl_16 stl_09">Vous devez créer votre compte, c'est gratuit, &nbsp;</span>
              </a>
            </div>
            <div className="stl_01" style={{ left: '8.75em', top: '26.0951em' }}>
              <span className="stl_19 stl_16 stl_09">veuillez entrer votre nom, courriel et choisir un &nbsp;</span>
            </div>
            <div className="stl_01" style={{ left: '8.75em', top: '27.2558em' }}>
              <span className="stl_19 stl_16 stl_09">mot de passe &nbsp;</span>
            </div>
            <div className="stl_01" style={{ left: '44.6945em', top: '24.9343em' }}>
              <a href="http://olab-plus.ca/mon-compte/" target="_blank">
                <span className="stl_19 stl_16 stl_09">Vous pouvez faire le suivi de vos commandes &nbsp;</span>
              </a>
            </div>
            <div className="stl_01" style={{ left: '44.6945em', top: '26.0951em' }}>
              <span className="stl_19 stl_16 stl_09">et vous pouvez voir l'historique de vos &nbsp;</span>
            </div>
            <div className="stl_01" style={{ left: '44.6945em', top: '27.2558em' }}>
              <span className="stl_19 stl_16 stl_09">anciennes commandes &nbsp;</span>
            </div>
            <div className="stl_01" style={{ left: '28.0722em', top: '30.0908em' }}>
              <span className="stl_20 stl_08 stl_21" style={{ wordSpacing: '-0em' }}>
                PLUS DE DÉTAILS &nbsp;
              </span>
            </div>
            <div className="stl_01" style={{ left: '10.1em', top: '30.0908em' }}>
              <span className="stl_20 stl_08 stl_21" style={{ wordSpacing: '-0em' }}>
                PLUS DE DÉTAILS &nbsp;
              </span>
            </div>
            <div className="stl_01" style={{ left: '46.0445em', top: '30.0908em' }}>
              <span className="stl_20 stl_08 stl_21" style={{ wordSpacing: '0em' }}>
                PLUS DE DÉTAILS &nbsp;
              </span>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Home;
